import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CustomBtnWrapper = styled.span``;

export const DotsBtn = styled.div`
  width: 20px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 2px;
`;

export const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.disabled};
  width: 5px;
  height: 5px;
  border-radius: 50%;
`;

export const MenuListContainer = styled.div`
  width: 242px;
  position: absolute;
  top: 20px;
  right: -40px;
  z-index: 100;

  ${breakpoint("xs", "xl")`
    width: 211px;
    right: -20px;
  `}
`;
export const MenuList = styled.div`
  height: min-content;
  border-radius: 8px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  padding: 14px 20px;

  ${breakpoint("xs", "xl")`
    font-size: ${({ theme }) => theme.size.caption};
    padding: 10px 15px;
  `}
`;

export const MenuListItem = styled.div`
  display: block;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  text-align: left;

  ${breakpoint("xs", "xl")`
      font-size: ${({ theme }) => theme.size.caption};
      padding: 8px;
    `}

  &:hover:not(.disabled) {
    opacity: 0.7;
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
`;
