import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CashIcon, CreditCard, DocumentIconAlt, ShieldLock, UsersGroup } from "assets/svg";
import { find } from "lodash";
import useRouterConfig from "routes/router";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { showErrorModal } from "helpers";
import { isKybAccepted } from "helpers/shared/kyb";
import { useBankAccountLink } from "hooks";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

import {
  ListAdminsResponseDto,
  queryAdminsControllerGetAdminsList,
} from "utils/swagger_react_query";

import { CountText } from "./styles";

export const useAdminSettingsPage = () => {
  const translationPrefix = `settings_pages.admin.index`;

  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const { routerConfig } = useRouterConfig();

  const [teamMembersDetails, setTeamMembersDetails] = useState<ListAdminsResponseDto>();
  const {
    data: { bankAccounts },
    actions: { refetchBankAccountsList },
  } = useBankAccountLink({
    loadingCallback: setLoading,
    shouldFetchBankAccountsListOnMount: false,
  });

  useEffect(() => {
    if (currentUser?.user?.userId) {
      initRequest();
    }
  }, []);

  const initRequest = async () => {
    try {
      setLoading(true);
      const _teamMembersInfoRes: ListAdminsResponseDto = await queryAdminsControllerGetAdminsList({
        page: "1",
        perPage: "1",
      });
      await refetchBankAccountsList(false);
      setTeamMembersDetails(_teamMembersInfoRes);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };
  const externalBankAccountsCount = bankAccounts?.length || 0;
  const internalBankAccountsCount = isKybAccepted(currentCompany) ? 1 : 0;
  const amountOfBankAccounts = externalBankAccountsCount + internalBankAccountsCount;

  const handleTeamMembersCardClick = () => navigate(routes.ADMIN_TEAM_MEMBERS);
  const handleBankAccountsCardClick = () => navigate(routes.ADMIN_BANK_ACCOUNTS);
  const handleDocumentsCardClick = () => navigate(routes.DOCUMENTS);
  const handlePayrollAndBenefitsCardClick = () => navigate(routes.PAYROLL_AND_BENEFITS);
  const handleSecuritySettingsCardClick = () => navigate(routes.ADMIN_SETTINGS_SECURITY);

  const _optionsList: NavigationCardProps[] = [
    {
      id: "payroll_and_benefits", //NOTE:::is also needed for tests
      icon: <CashIcon />,
      title: <Trans i18nKey={`${translationPrefix}.options.payroll_and_benefits.title`} />,
      description: (
        <Trans i18nKey={`${translationPrefix}.options.payroll_and_benefits.description`} />
      ),
      onClick: handlePayrollAndBenefitsCardClick,
    },
    {
      id: "bank_account",
      icon: <CreditCard />,
      title: <Trans i18nKey={`${translationPrefix}.options.bank_account.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.options.bank_account.description`} />,
      onClick: handleBankAccountsCardClick,
      additionalContent: bankAccounts && (
        <CountText>
          <CreditCard />
          <Trans
            i18nKey={`${translationPrefix}.options.bank_account.count`}
            values={{ count: amountOfBankAccounts }}
          />
        </CountText>
      ),
    },
    {
      id: "team_management",
      icon: <UsersGroup />,
      title: <Trans i18nKey={`${translationPrefix}.options.team_management.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.options.team_management.description`} />,
      onClick: handleTeamMembersCardClick,
      additionalContent: (
        <CountText>
          <UsersGroup />
          <Trans
            i18nKey={`${translationPrefix}.options.team_management.count`}
            values={{ count: teamMembersDetails?.total || 0 }}
          />
        </CountText>
      ),
    },
    {
      id: "documents",
      icon: <DocumentIconAlt />,
      title: <Trans i18nKey={`${translationPrefix}.options.documents.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.options.documents.description`} />,
      onClick: handleDocumentsCardClick,
    },
    {
      id: "security",
      icon: <ShieldLock />,
      title: <Trans i18nKey={`${translationPrefix}.options.security.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.options.security.description`} />,
      onClick: handleSecuritySettingsCardClick,
    },
  ];

  const optionsList = _optionsList.filter((item) => {
    return (
      !(
        item.onClick === handleBankAccountsCardClick &&
        find(routerConfig, (item) => item.path === routes.ADMIN_BANK_ACCOUNTS)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handleTeamMembersCardClick &&
        find(routerConfig, (item) => item.path === routes.ADMIN_TEAM_MEMBERS)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handleDocumentsCardClick &&
        find(routerConfig, (item) => item.path === routes.DOCUMENTS)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handlePayrollAndBenefitsCardClick &&
        find(routerConfig, (item) => item.path === routes.PAYROLL_AND_BENEFITS)?.disableCondition?.(
          currentUser,
          currentCompany,
        )
      ) &&
      !(
        item.onClick === handleSecuritySettingsCardClick &&
        find(
          routerConfig,
          (item) => item.path === routes.ADMIN_SETTINGS_SECURITY,
        )?.disableCondition?.(currentUser, currentCompany)
      )
    );
  });

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
    },
    pageData: {
      teamMembersDetails,
      bankAccounts,
      optionsList,
    },
    actions: {
      handleTeamMembersCardClick,
      handleBankAccountsCardClick,
      handleDocumentsCardClick,
      handlePayrollAndBenefitsCardClick,
    },
  };
};
