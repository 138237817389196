import { ReactNode } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { ProtectedRoute, ServiceRoute, UnauthorizedRoute } from "routes/routeGuards";

import { Pages } from "permissions";
import IconsPage from "pages/_debug/IconsPage";
import {
  AdminSignUpPage,
  CheckEmailPage,
  EmployeeSignUp,
  ResetPasswordPage,
  SignInMfaPage,
  SignInPage,
  SignUpPage,
} from "pages/Auth";
import { BusinessOwnershipStructurePage } from "pages/Banking/admin";
import { RewardsPage } from "pages/Banking/employee";
import {
  BankingPage,
  CardsDetailsPage,
  MonthlyStatements,
  TransferFundsPage,
} from "pages/Banking/shared";
import InsufficientPermissionsPage from "pages/InsufficientPermissionsPage";
import { AdminProfilePage, EmployeeProfilePage } from "pages/MyProfile";
import Page404 from "pages/Page404";
import { AdminPayPage, EmployeePayPage } from "pages/Pay";
import { AdminEditWorkerPage, AdminPeoplePage, AdminWorkerDetailsPage } from "pages/People";
import {
  AdminDocumentsPage,
  AdminPayrollAndBenefitsPage,
  AdminSettingsPage,
  AdminTeamMembersPage,
  BankAccountsPage,
  EmployeeSettingsPage,
  PasswordManagement,
  SecuritySettingPage,
  SettingsMFAPage,
} from "pages/Settings";
import EmployeePayDistributionPage from "pages/Settings/employee/EmployeePayDistributionPage";
import { AdminSetupPage, EmployeeSetupPage } from "pages/Setup";

import {
  getAdminBankingBlockCondition,
  getAdminPagesBlockCondition,
  getBankingAdvancedBlockCondition,
  getBankingBlockCondition,
  getEmployeeBankingBlockCondition,
  getEmployeePagesBlockCondition,
  useRouteHelpers,
} from "./helpers";
import PageViewTracker from "./PageViewTracker";
import { redirectRoutes } from "./redirectRoutes";
import routes from "./routes";
import { IRouterConfigItem } from "./types";

export const useRouterConfig = () => {
  const { getDefaultRoute, shouldShowEmployeeOnboardingPage, shouldShowAdminOnboardingPage } =
    useRouteHelpers();
  const translationPrefix = "common.pagesInfo";

  const routerConfig: IRouterConfigItem[] = [
    {
      path: "/",
      page: <Navigate to={getDefaultRoute()} />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />, //NOTE:::Using Protected Route, because most of routing logic is put there
    },
    //NOTE:::Auth
    {
      path: routes.SIGN_IN,
      page: <SignInPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },
    {
      path: routes.SIGN_IN_COMPLETE,
      page: <SignInPage />,
    },

    {
      path: routes.SIGN_UP,
      page: <SignUpPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },
    {
      path: routes.SIGN_UP_COMPLETE,
      page: <SignUpPage />,
    },
    {
      path: routes.SIGN_IN_COMPLETE_MFA,
      page: <SignInMfaPage />,
    },
    {
      path: routes.RESET_PASSWORD,
      page: <ResetPasswordPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
      children: [
        {
          path: routes.RESET_PASSWORD_WITH_TOKEN,
          page: <ResetPasswordPage />,
        },
      ],
    },

    {
      path: routes.ADMIN_SIGN_UP,
      page: <AdminSignUpPage />,
    },
    {
      path: routes.CHECK_EMAIL,
      page: <CheckEmailPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },

    //NOTE:::Shared Pages
    {
      path: routes.BANKING,
      page: <BankingPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getBankingBlockCondition,
      breadcrumbs: [routes.BANKING],
      title: `${translationPrefix}.BANKING.title`,
    },
    {
      path: routes.BANKING_MONTHLY_STATEMENTS,
      page: <MonthlyStatements />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getBankingBlockCondition,
      breadcrumbs: [routes.BANKING, routes.BANKING_MONTHLY_STATEMENTS],
      title: `${translationPrefix}.BANKING_MONTHLY_STATEMENTS.title`,
    },
    {
      path: routes.BANKING_TRANSFER_FUNDS,
      page: <TransferFundsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getBankingAdvancedBlockCondition,
      breadcrumbs: [routes.BANKING, routes.BANKING_TRANSFER_FUNDS],
      title: `${translationPrefix}.BANKING_TRANSFER_FUNDS.title`,
    },
    {
      path: routes.BANKING_BUSINESS_OWNERSHIP_STRUCTURE,
      page: <BusinessOwnershipStructurePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getAdminBankingBlockCondition,
      breadcrumbs: [routes.BANKING, routes.BANKING_BUSINESS_OWNERSHIP_STRUCTURE],
      title: `${translationPrefix}.BANKING_BUSINESS_OWNERSHIP_STRUCTURE.title`,
    },

    //NOTE:::Admin pages

    {
      path: routes.ADMIN_SETUP,
      page: <AdminSetupPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETUP,
      disableCondition: (...args) => !shouldShowAdminOnboardingPage(...args),
      title: `${translationPrefix}.ADMIN_SETTINGS.title`,
    },
    {
      path: routes.PEOPLE,
      page: <AdminPeoplePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE,
      breadcrumbs: [routes.PEOPLE],
      title: `${translationPrefix}.PEOPLE.title`,
    },
    {
      path: routes.BANKING_CARDS_ADMIN,
      page: <CardsDetailsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getBankingBlockCondition,
      breadcrumbs: [routes.BANKING, routes.BANKING_CARDS_ADMIN],
      title: `${translationPrefix}.BANKING_CARDS_ADMIN.title`,
    },
    {
      path: routes.PEOPLE_EDIT_WORKER,
      page: <AdminEditWorkerPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE_EDIT_WORKER,
      breadcrumbs: [routes.PEOPLE, routes.PEOPLE_EDIT_WORKER],
      title: `${translationPrefix}.PEOPLE_EDIT_WORKER.title`,
    },
    {
      path: routes.PEOPLE_WORKER_DETAILS,
      page: <AdminWorkerDetailsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE_WORKER_DETAILS,
      breadcrumbs: [routes.PEOPLE, routes.PEOPLE_WORKER_DETAILS],
      title: `${translationPrefix}.PEOPLE_WORKER_DETAILS.title`,
    },
    {
      path: routes.ADMIN_PAY,
      page: <AdminPayPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_PAY,
      breadcrumbs: [routes.ADMIN_PAY],
      title: `${translationPrefix}.ADMIN_PAY.title`,
    },
    {
      path: routes.ADMIN_SETTINGS,
      page: <AdminSettingsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETTINGS,
      breadcrumbs: [routes.ADMIN_SETTINGS],
      title: `${translationPrefix}.ADMIN_SETTINGS.title`,
    },
    {
      path: routes.ADMIN_PROFILE,
      page: <AdminProfilePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_PROFILE,
      breadcrumbs: [routes.ADMIN_PROFILE],
      title: `${translationPrefix}.ADMIN_PROFILE.title`,
    },
    {
      path: routes.ADMIN_BANK_ACCOUNTS,
      page: <BankAccountsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_BANK_ACCOUNTS,
      disableCondition: getAdminPagesBlockCondition,
      breadcrumbs: [routes.ADMIN_SETTINGS, routes.ADMIN_BANK_ACCOUNTS],
      title: `${translationPrefix}.ADMIN_BANK_ACCOUNTS.title`,
    },
    {
      path: routes.DOCUMENTS,
      page: <AdminDocumentsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.DOCUMENTS,
      breadcrumbs: [routes.ADMIN_SETTINGS, routes.DOCUMENTS],
      title: `${translationPrefix}.DOCUMENTS.title`,
    },
    {
      path: routes.ADMIN_TEAM_MEMBERS,
      page: <AdminTeamMembersPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_TEAM_MEMBERS,
      breadcrumbs: [routes.ADMIN_SETTINGS, routes.ADMIN_TEAM_MEMBERS],
      title: `${translationPrefix}.ADMIN_TEAM_MEMBERS.title`,
    },
    {
      path: routes.PAYROLL_AND_BENEFITS,
      page: <AdminPayrollAndBenefitsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PAYROLL_AND_BENEFITS,
      disableCondition: getAdminPagesBlockCondition,
      breadcrumbs: [routes.ADMIN_SETTINGS, routes.PAYROLL_AND_BENEFITS],
      title: `${translationPrefix}.PAYROLL_AND_BENEFITS.title`,
    },
    {
      path: routes.ADMIN_SETTINGS_MFA_AUTHENTICATION,
      page: <SettingsMFAPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETTINGS,
      disableCondition: getAdminPagesBlockCondition,
      breadcrumbs: [
        routes.ADMIN_SETTINGS,
        routes.ADMIN_SETTINGS_SECURITY,
        routes.ADMIN_SETTINGS_MFA_AUTHENTICATION,
      ],
      title: `${translationPrefix}.ADMIN_SETTINGS_MFA_AUTHENTICATION.title`,
    },
    {
      path: routes.ADMIN_SETTINGS_SECURITY,
      page: <SecuritySettingPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETTINGS,
      breadcrumbs: [routes.ADMIN_SETTINGS, routes.ADMIN_SETTINGS_SECURITY],
      title: `${translationPrefix}.SETTINGS_SECURITY.title`,
    },
    {
      path: routes.ADMIN_SETTINGS_PASSWORD_MANAGEMENT,
      page: <PasswordManagement />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETTINGS,
      breadcrumbs: [
        routes.ADMIN_SETTINGS,
        routes.ADMIN_SETTINGS_SECURITY,
        routes.ADMIN_SETTINGS_PASSWORD_MANAGEMENT,
      ],
      title: `${translationPrefix}.SETTINGS_PASSWORD_MANAGEMENT.title`,
    },

    //NOTE:::Employee pages
    {
      path: routes.EMPLOYEE_SIGN_UP,
      page: <EmployeeSignUp />,
    },
    {
      path: routes.EMPLOYEE_SETUP,
      page: <EmployeeSetupPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETUP,
      disableCondition: (...args) => !shouldShowEmployeeOnboardingPage(...args),
      title: `${translationPrefix}.EMPLOYEE_SETUP.title`,
    },
    {
      path: routes.EMPLOYEE_PAY,
      page: <EmployeePayPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_PAY,
      disableCondition: getEmployeePagesBlockCondition,
      breadcrumbs: [routes.EMPLOYEE_PAY],
      title: `${translationPrefix}.EMPLOYEE_PAY.title`,
    },
    {
      path: routes.EMPLOYEE_SETTINGS,
      page: <EmployeeSettingsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      breadcrumbs: [routes.EMPLOYEE_SETTINGS],
      title: `${translationPrefix}.EMPLOYEE_SETTINGS.title`,
    },
    {
      path: routes.EMPLOYEE_PROFILE,
      page: <EmployeeProfilePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_PROFILE,
      disableCondition: getEmployeePagesBlockCondition,
      breadcrumbs: [routes.EMPLOYEE_SETTINGS, routes.EMPLOYEE_PROFILE],
      title: `${translationPrefix}.EMPLOYEE_PROFILE.title`,
    },
    {
      path: routes.EMPLOYEE_BANK_ACCOUNTS,
      page: <BankAccountsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_BANK_ACCOUNTS,
      disableCondition: getEmployeePagesBlockCondition,
      breadcrumbs: [routes.EMPLOYEE_SETTINGS, routes.EMPLOYEE_BANK_ACCOUNTS],
      title: `${translationPrefix}.EMPLOYEE_BANK_ACCOUNTS.title`,
    },
    {
      path: routes.EMPLOYEE_BANKING_REWARDS,
      page: <RewardsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getEmployeeBankingBlockCondition,
      breadcrumbs: [routes.BANKING, routes.EMPLOYEE_BANKING_REWARDS],
      title: `${translationPrefix}.EMPLOYEE_BANKING_REWARDS.title`,
    },
    {
      path: routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION,
      page: <EmployeePayDistributionPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      disableCondition: getEmployeePagesBlockCondition,
      breadcrumbs: [routes.EMPLOYEE_SETTINGS, routes.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION],
      title: `${translationPrefix}.EMPLOYEE_SETTINGS_PAY_DISTRIBUTION.title`,
    },
    {
      path: routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION,
      page: <SettingsMFAPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      disableCondition: getEmployeePagesBlockCondition,
      breadcrumbs: [
        routes.EMPLOYEE_SETTINGS,
        routes.EMPLOYEE_SETTINGS_SECURITY,
        routes.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION,
      ],
      title: `${translationPrefix}.EMPLOYEE_SETTINGS_MFA_AUTHENTICATION.title`,
    },
    {
      path: routes.BANKING_CARDS_EMPLOYEE,
      page: <CardsDetailsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.BANKING,
      disableCondition: getBankingBlockCondition,
      breadcrumbs: [routes.BANKING, routes.BANKING_CARDS_EMPLOYEE],
      title: `${translationPrefix}.BANKING_CARDS_EMPLOYEE.title`,
    },
    {
      path: routes.EMPLOYEE_SETTINGS_SECURITY,
      page: <SecuritySettingPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      breadcrumbs: [routes.EMPLOYEE_SETTINGS, routes.EMPLOYEE_SETTINGS_SECURITY],
      title: `${translationPrefix}.SETTINGS_SECURITY.title`,
    },
    {
      path: routes.EMPLOYEE_SETTINGS_PASSWORD_MANAGEMENT,
      page: <PasswordManagement />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      breadcrumbs: [
        routes.EMPLOYEE_SETTINGS,
        routes.EMPLOYEE_SETTINGS_SECURITY,
        routes.EMPLOYEE_SETTINGS_PASSWORD_MANAGEMENT,
      ],
      title: `${translationPrefix}.SETTINGS_PASSWORD_MANAGEMENT.title`,
    },

    //NOTE::: Routes for redirecting old URLs to new URLs
    ...redirectRoutes.map((route) => ({
      path: route.oldRoute,
      page: <Navigate to={route.newRoute} replace />,
    })),
    //NOTE:::NOTE:::Service
    {
      path: routes.PAGE_404,
      page: <Page404 />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
    {
      path: routes.INSUFFICIENT_PERMISSIONS,
      page: <InsufficientPermissionsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
    {
      path: routes.DEBUG_ICONS_PAGE,
      page: <IconsPage />,
      guard: (routeProps) => <ServiceRoute {...routeProps} />,
    },
    {
      path: "*",
      page: <Page404 />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
  ];

  const renderRouterConfig = () => {
    return routerConfig.map((route) => {
      const { page, guard } = route;
      let Page: ReactNode = page;
      if (guard) {
        Page = guard({ ...route, children: Page });
      }

      return (
        <Route key={route.path} path={route.path} element={Page}>
          {route.children?.map((child) => {
            const { page, guard } = child;

            let ChildPage: ReactNode = child.page;

            if (guard) {
              ChildPage = guard({ ...child, children: page });
            }

            return (
              <Route
                key={child.path}
                path={child.path}
                element={ChildPage}
                index={route.path === "/"}
              />
            );
          })}
        </Route>
      );
    });
  };

  const rootChildren = () => (
    <>
      <Outlet />
      <PageViewTracker />
    </>
  );

  const getCreatedRouter = () => {
    const createdRoutes = createRoutesFromElements(renderRouterConfig());

    const browserRouter = createBrowserRouter([
      {
        id: "root",
        element: rootChildren(),
        children: createdRoutes,
      },
    ]);
    return browserRouter;
  };

  return {
    routerConfig,
    renderRouterConfig,
    getCreatedRouter,
  };
};

export default useRouterConfig;
