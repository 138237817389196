import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { Formik } from "formik";

import { BankAccountConnectionMethodModal } from "components/BankAccountComponents";
import { isPayDistributionIncludesPartnerAccount } from "components/PayDistribution/helpers";
import { PayDistributionWidgetType } from "components/PayDistribution/types";

import {
  ButtonsContainer,
  FullScreenLoader,
  Section,
  SectionInnerRow,
  SectionInnerTitle,
  SectionSubtitle,
  SectionTitle,
} from "uikit";

import { IExternalWidgetProps, IWidgetProps, IWidgetPropsInner } from "./types";
import { useBankAccountsForm } from "./useBankAccountsForm";
import { validationSchema } from "./validationSchema";
import {
  AdditionalButtonsContainer,
  BankAccountsContainer,
  Container,
  SectionButtons,
  StyledDefaultCompanyBankAccountBenefitsBanner,
} from "./styles";

import "./styles.scss";

const BankAccountsForm = forwardRef<IExternalWidgetProps, IWidgetPropsInner>((props, ref) => {
  const translationPrefix = `components.pay_distribution.bank_accounts_form`;

  const { className } = props;
  const {
    isLoading,
    values,
    setValues,
    currentUser,
    isInEditMode,
    showAddBankAccountModal,
    renderSectionButtons,
    renderBankAccountItems,
    renderAdditionalButtons,
    renderEarlyPayChangeBankAccountModal,
    renderBackButton,
    renderSubmitButton,
    isDataUnsaved,
    handleBankAccountAddModalContinue,
    handleBankAccountAddModalCancel,
    confirmButtonIsVisible,
    handleEditClick,
  } = useBankAccountsForm(props);

  const shouldHideBanner = () => {
    if (props.widgetType === PayDistributionWidgetType.ONBOARDING) {
      return (
        isPayDistributionIncludesPartnerAccount(values?.payDistributionRules || []) ||
        !currentUser?.user?.hasPersonalBankAccount ||
        isInEditMode
      );
    } else {
      return (
        isPayDistributionIncludesPartnerAccount(values?.payDistributionRules || []) ||
        confirmButtonIsVisible ||
        isInEditMode ||
        !currentUser?.user?.hasPersonalBankAccount
      );
    }
  };

  const onSetPaidBankAccountAsDefault = () => {
    handleEditClick();
    const defaultValue = props.onSetCompanyBankAccountAsDefault?.();
    if (defaultValue) {
      setValues(defaultValue);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      isDataUnsaved,
    }),
    [values, currentUser, isInEditMode],
  );

  return (
    <Container className={className}>
      {!!isLoading && <FullScreenLoader />}
      <Section>
        <SectionInnerRow>
          <SectionTitle>
            <SectionInnerTitle>
              <Trans i18nKey={`${translationPrefix}.title`} />
            </SectionInnerTitle>
            <SectionSubtitle>
              <Trans i18nKey={`${translationPrefix}.subtitle`} />
            </SectionSubtitle>
          </SectionTitle>
          <SectionButtons>{renderSectionButtons()}</SectionButtons>
        </SectionInnerRow>

        <BankAccountsContainer>{renderBankAccountItems()}</BankAccountsContainer>

        {!shouldHideBanner() && (
          <StyledDefaultCompanyBankAccountBenefitsBanner
            onSetDefault={onSetPaidBankAccountAsDefault}
          />
        )}
      </Section>

      <AdditionalButtonsContainer>{renderAdditionalButtons()}</AdditionalButtonsContainer>

      <BankAccountConnectionMethodModal
        isOpen={showAddBankAccountModal}
        onClose={handleBankAccountAddModalCancel}
        onContinue={handleBankAccountAddModalContinue}
      />

      <ButtonsContainer>
        {renderBackButton()}
        {renderSubmitButton()}
      </ButtonsContainer>

      <>{renderEarlyPayChangeBankAccountModal()}</>
    </Container>
  );
});

const BankAccountsFormContainer = forwardRef<IExternalWidgetProps, IWidgetProps>((props, ref) => {
  const { initialFormData, bankAccounts } = props;

  const componentRef = useRef<IExternalWidgetProps>(null);

  const [svoc, setSvoc] = useState(false);

  const handleSaveSuccess = () => {
    setSvoc(false);
  };

  const handleSaveError = () => {
    setSvoc(true);
  };

  useImperativeHandle(ref, () => ({
    isDataUnsaved: () => {
      return !!componentRef.current?.isDataUnsaved?.();
      /* TODO::: Try to optimize it later;
      As of now, if I pass it like isDataUnsaved: componentRef.current?.isDataUnsaved, the values inside the function do not update
      Research, which dependencies should change for this to work ([componentRef.current] does not help) */
    },
  }));

  return (
    <>
      {bankAccounts && initialFormData && (
        <Formik
          initialValues={initialFormData}
          onSubmit={() => undefined}
          validationSchema={validationSchema}
          validateOnChange={svoc}
          validateOnBlur={false}
          enableReinitialize
        >
          <BankAccountsForm
            {...props}
            bankAccounts={bankAccounts}
            ref={componentRef}
            onSaveSuccess={handleSaveSuccess}
            onSaveError={handleSaveError}
          />
        </Formik>
      )}
    </>
  );
});

BankAccountsForm.displayName = "BankAccountsForm";
BankAccountsFormContainer.displayName = "BankAccountsFormContainer";

export default BankAccountsFormContainer;
